













































import Vue from "vue";
import RowLayout from "./RowLayout.vue";
import { TableText } from "@/ui/Table";
import Employee from "@/components/Employee.vue";
import { EmployeeOnline, SearchSuggestionsType } from "@/schemas/dashboard";
import { Tab } from "@/ui";
import { Moment } from "moment";
import { Location } from "vue-router";

export default Vue.extend({
  props: {
    record: Object as () => EmployeeOnline,
    isFirst: Boolean,
    isLast: Boolean,
    mistakeArrival: Boolean,
    leftEarly: Boolean,
    lateNow: Boolean,
    toBeLate: Boolean
  },
  data: () => ({
    minuteTimer: 0,
    updateKey: 0,
    employeeQueryKey: SearchSuggestionsType.Employee
  }),
  components: {
    RowLayout,
    TableText,
    Employee,
    Tab
  },
  computed: {
    dateTzInterval(): string {
      const timezone = this.record.terminal.timezone;
      let startDate = this.$moment(this.record.schedule_record?.start_time).tz(timezone);
      let endDate = this.$moment(this.record.schedule_record?.end_time).tz(timezone);
      if (!startDate.isValid() || !endDate.isValid()) return "";
      return `${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;
    },
    staticStatusText(): string {
      if (this.leftEarly) {
        return this.getEarlyDepartureMessage();
      }

      if (this.record.is_online) {
        if (this.toBeLate && !this.mistakeArrival) {
          return this.getLateMessage();
        } else {
          return this.getArrivalMessage();
        }
      }
      return "";
    },
    employeeReportLink(): Location {
      let employee = this.record.employee;
      return {
        name: "reports",
        query: {
          [this.employeeQueryKey]: this.$cStore.employeeSearch.concatEmployeeName(employee)
        },
        params: { openEmployee: String(employee.id) }
      };
    },
    employeeScheduleLink(): Location {
      let employee = this.record.employee;
      return {
        name: "schedule",
        query: {
          [this.employeeQueryKey]: this.$cStore.employeeSearch.concatEmployeeName(employee)
        }
      };
    }
  },
  methods: {
    getDurationHHmm(startDate: Moment, endDate: Moment): { value: string; negative: boolean } {
      startDate.startOf("minute");
      endDate.startOf("minute");
      let duration = this.$moment.duration(startDate.diff(endDate));
      let h = duration.hours();
      let m = duration.minutes();
      let negative = h < 0 || m < 0;
      h = Math.abs(h);
      m = Math.abs(m);
      return {
        value: `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`,
        negative
      };
    },
    updateTable() {
      clearTimeout(this.minuteTimer);
      let now = new Date();
      let offset = 60 * 1000 - now.getSeconds() * 1000 - now.getMilliseconds();
      this.minuteTimer = setTimeout(this.updateTable.bind(this), offset);
      this.updateKey = (this.updateKey + 1) % 2;
    },
    getLateNowMessage(): string {
      if (!this.record.schedule_record) return "Нет в графике";
      let timeNow = this.$moment();
      let scheduleStart = this.$moment(this.record.schedule_record.start_time);
      let HHmm = this.getDurationHHmm(timeNow, scheduleStart);
      return `Опаздывает на ${HHmm.value}`;
    },
    getEarlyDepartureMessage(): string {
      if (!this.record.time_record?.leaving_time || !this.record.schedule_record?.end_time)
        return "";
      let shedule_endTime = this.$moment(this.record.schedule_record.end_time);
      let leavingTime = this.$moment(this.record.time_record.leaving_time);
      let HHmm = this.getDurationHHmm(shedule_endTime, leavingTime);
      return `Ушел раньше на ${HHmm.value}`;
    },
    getLateMessage(): string {
      if (!this.record.time_record?.arrival_time || !this.record.schedule_record?.start_time)
        return "";
      let startTime = this.$moment(this.record.time_record.arrival_time);
      let scheduleStart = this.$moment(this.record.schedule_record.start_time);
      let HHmm = this.getDurationHHmm(scheduleStart, startTime);
      return `Опоздал на ${HHmm.value}`;
    },
    getArrivalMessage(): string {
      if (!this.record.time_record?.arrival_time) return "";
      const timezone = this.record.terminal.timezone;
      const arrivalTime = this.record.time_record.arrival_time;
      const arrivalTimeTz = this.$moment(arrivalTime).tz(timezone);
      return `Пришел в ${arrivalTimeTz.format("HH:mm")}`;
    }
  },
  watch: {
    lateNow: {
      handler(newVal) {
        if (newVal) {
          this.updateTable();
        }
      },
      immediate: true
    }
  }
});
