














import Vue from "vue";
import RowLayout from "./RowLayout.vue";
import { TableText } from "@/ui/Table";

export default Vue.extend({
  components: {
    RowLayout,
    TableText
  }
});
