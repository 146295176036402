














import Vue from "vue";
import PageLayout from "@/components/pageWrap.vue";
import TableOnline from "@/components/TableOnline";
import EmployeesSearch from "@/components/EmployeesSearch.vue";
export default Vue.extend({
  name: "online",
  metaInfo: {
    title: "Онлайн"
  },
  components: {
    PageLayout,
    TableOnline,
    EmployeesSearch
  },
  data: () => ({
    isSorted: true
  })
});
