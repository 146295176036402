









































import Vue from "vue";

import { TableText, TableLine } from "@/ui/Table";
import { Loader } from "@/ui";

import { partition, orderBy } from "lodash";
import employee from "@/components/Employee.vue";
import { EmployeeOnline, Employee } from "@/schemas/dashboard";
import { Moment } from "moment";
import { getOnlineEmployee } from "@/api/dashboard";
import TableHeader from "./Header.vue";
import TableRow from "./Row.vue";
import { TerminalModule } from "@/store/modules/terminal";
import { TableWrap } from "@/ui/Table";

interface ComponentData {
  allEmployees: EmployeeOnline[];
  [propName: string]: any;
}

//TODO: переделать, вынести все расчеты таблицы из строк - сюда, дочерних компонентах лишь выводить состояния
/*
TODO: наоборот, вынести логику отображение строк в строки, оставить только сортировки
  или наоборот, здесь лишь разбивать на группы, а отображение строк вынести в строки, строки будут эмитить необходимые данные на апдейт и здесь будет происходить пересортировка
  тогда на начальной инициализации будет происходит десяток сортировок
  может сделать таймер на апдейт таблицы, раз в 100мс происходит пересчет в новый стейт
  сделать возможность пуша пересчета данных из дочернего компонента
*/
//TODO: по хорошему повесить гварды на проверки типо employeeLateNow, должна показывать что у employee отсутсвует timeRecord
export default Vue.extend({
  props: {
    isSorted: {
      type: Boolean,
      default: true
    }
  },
  data(): ComponentData {
    return {
      allEmployees: [],
      employeesLoading: true
    };
  },
  computed: {
    employeesGroups(): [EmployeeOnline[], EmployeeOnline[]] {
      return partition(this.availableOnlineEmployees, this.checkAttentionEmployee);
    },
    attentionGroup(): EmployeeOnline[] {
      const employees = this.employeesGroups[0];
      let sorted = orderBy(employees, [
        e => this.getAttentionGroupDelta(e),
        e => this.concatName(e.employee)
      ]);
      return this.isSorted ? sorted : employees;
    },
    normalGroup(): EmployeeOnline[] {
      const employees = this.employeesGroups[1];
      let sorted = orderBy(employees, [
        e => !this.employeeMistakeArrival(e),
        e => this.getNormalGroupDelta(e),
        e => this.concatName(e.employee)
      ]);
      return this.isSorted ? sorted : employees;
    },
    allEmployees_sorted(): EmployeeOnline[] {
      return [...this.attentionGroup, ...this.normalGroup];
    },
    attentionGroupLength(): number {
      return this.attentionGroup.length;
    },
    availableEmployees(): Employee[] {
      return this.$cStore.employeeSearch.filteredEmployees;
    },
    availableOnlineEmployees(): EmployeeOnline[] {
      return this.allEmployees.filter(onlineEmployee => {
        return this.availableEmployees.find(employee => employee.id === onlineEmployee.employee.id);
      });
    },
    termStore(): TerminalModule {
      return this.$cStore.terminal;
    },
    activeTerminalId(): number | undefined {
      return this.$cStore.terminal.terminalId;
    }
  },
  mounted() {
    if (this.termStore.checkReady()) {
      this.getEmployees();
    }
  },
  methods: {
    async getEmployees() {
      if (!this.termStore.checkReady()) return;
      this.employeesLoading = true;
      this.allEmployees = await getOnlineEmployee(this.activeTerminalId);
      this.employeesLoading = false;
    },
    checkAttentionEmployee(employee: EmployeeOnline): boolean {
      return this.employeeLateNow(employee) || this.employeeLeftEarly(employee);
    },
    checkFirstRow(index: number): boolean {
      return index === this.attentionGroupLength;
    },
    checkLastRow(index: number): boolean {
      return (
        index === this.attentionGroupLength - 1 || index === this.allEmployees_sorted.length - 1
      );
    },
    employeeLateNow(employee: EmployeeOnline): boolean {
      return (
        !!employee.schedule_record &&
        !employee.time_record &&
        !this.employeeMistakeArrival(employee)
      );
    },
    employeeLeftEarly(employee: EmployeeOnline): boolean {
      if (
        !employee.time_record?.leaving_time ||
        !employee.schedule_record?.end_time ||
        this.employeeMistakeArrival(employee)
      )
        return false;
      let expectedTime = employee.schedule_record.end_time;
      let leavingTime = employee.time_record.leaving_time;
      return !!leavingTime && this.$moment(expectedTime).diff(leavingTime, "minutes") > 0;
    },
    employeeMistakeArrival(employee: EmployeeOnline): boolean {
      return employee.schedule_record === null;
    },
    toBeLate(employee: EmployeeOnline): boolean {
      if (!employee.time_record?.arrival_time || !employee.schedule_record?.start_time)
        return false;
      let arrival = employee.time_record.arrival_time;
      let schedule = employee.schedule_record.start_time;
      return this.$moment(schedule).diff(arrival) < 0;
    },
    getDuration(startDate: Moment, endDate: Moment): number {
      startDate.startOf("minute");
      endDate.startOf("minute");
      let duration = this.$moment.duration(startDate.diff(endDate));
      return duration.hours() * 60 + duration.minutes();
    },
    //schedule - now
    nowDelta(employee: EmployeeOnline): number | null {
      if (!employee.schedule_record) return null;
      let now = this.$moment();
      let scheduleStart = this.$moment(employee.schedule_record.start_time);
      return this.getDuration(scheduleStart, now);
    },
    //schedule - start
    arrivalDelta(employee: EmployeeOnline): number | null {
      if (!employee.time_record?.arrival_time || !employee.schedule_record?.start_time) return null;
      let startTime = this.$moment(employee.time_record.arrival_time);
      let scheduleStart = this.$moment(employee.schedule_record.start_time);
      return this.getDuration(scheduleStart, startTime);
    },
    //leavingTime - schedule
    leaveDelta(employee: EmployeeOnline): number | null {
      if (!employee.time_record?.leaving_time || !employee.schedule_record?.end_time) return null;
      let shedule_endTime = this.$moment(employee.schedule_record.end_time);
      let leavingTime = this.$moment(employee.time_record.leaving_time);
      return this.getDuration(leavingTime, shedule_endTime);
    },
    getDelta(employee: EmployeeOnline, where: "start" | "end"): number | null {
      let delta = 0;
      if (where === "start") {
        let arrivalDelta = this.arrivalDelta(employee);
        return arrivalDelta === null ? this.nowDelta(employee) : this.arrivalDelta(employee);
      } else if (where === "end") {
        return this.leaveDelta(employee);
      }
      return null;
    },
    attentionCompare(emp_a: EmployeeOnline, emp_b: EmployeeOnline): -1 | 1 | 0 {
      if (!this.checkAttentionEmployee(emp_a) || !this.checkAttentionEmployee(emp_b)) return 0;
      let delta_a = this.getAttentionGroupDelta(emp_a);
      let delta_b = this.getAttentionGroupDelta(emp_b);
      if (delta_a === null) return 1;
      if (delta_b === null) return -1;

      return delta_a < delta_b ? -1 : 1; //delta < 0 означает время недоработки
    },
    getAttentionGroupDelta(employee: EmployeeOnline): number | null {
      if (this.employeeLateNow(employee)) {
        return this.getDelta(employee, "start");
      } else if (this.employeeLeftEarly(employee)) {
        return this.getDelta(employee, "end");
      }
      return null;
    },
    getNormalGroupDelta(employee: EmployeeOnline): number {
      if (this.checkAttentionEmployee(employee) || this.employeeMistakeArrival(employee)) return 0;
      return Math.max(this.getDelta(employee, "start") || 0, 0);
    },
    concatName(employee: Employee): string {
      return this.$cStore.employeeSearch.concatEmployeeName(employee);
    }
  },
  components: {
    TableLine,
    TableRow,
    Loader,
    TableHeader,
    TableWrap
  },
  destroyed() {
    clearInterval(this.minuteTimer);
  },
  watch: {
    allEmployees(employeesOnline: EmployeeOnline[]) {
      this.$cStore.employeeSearch.SET_ALL_EMPLOYEES(
        employeesOnline.map(employee => employee.employee)
      );
    },
    "termStore.activeTerminal": function() {
      this.getEmployees();
    }
  }
});
