

















import Vue from "vue";
import { TableRow } from "@/ui/Table";
export default Vue.extend({
  props: {
    isHeader: Boolean,
    isFirst: Boolean,
    isLast: Boolean
  },
  computed: {
    colComponent() {
      return this.isHeader ? "th" : "td";
    },
    mods(): string {
      let mods = [];
      if (this.isHeader || this.isFirst) {
        mods.push("first");
      }
      if (this.isLast) {
        mods.push("last");
      }
      return mods.join(",");
    }
  },
  components: {
    TableRow
  }
});
