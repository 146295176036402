<template>
  <div></div>
</template>

<script>
export default {
  metaInfo: {
    title: "Страница не найдена"
  },
  beforeMount() {
    this.$router.replace("/");
  }
};
</script>
